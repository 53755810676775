<template>
  <div class="withdraw">
    <SimpleHeader></SimpleHeader>
    <div class="ml">
      <el-button type="text" class="el-icon-back fs16" @click="$router.go(-1)"> {{ $t(207) }}</el-button>
    </div>
    <div class="fund-box">
      <h3 class="title">{{ $t(316) }}</h3>
      <el-table :data="list" v-loading="loading">
        <el-table-column prop="coin" :label="$t(196)" width="80"></el-table-column>
        <el-table-column prop="chain" :label="$t(229)" width="80"></el-table-column>
        <el-table-column prop="address" :label="$t(226)"></el-table-column>
        <el-table-column prop="memo" :label="'Memo'" width="100"></el-table-column>
        <el-table-column :label="$t(315)" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="deleteWallet(scope.row.id)"
              >{{ $t(314) }}</el-button
            >
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="text-center">
            <img src="../../assets/img/nodata.png" alt="" style="width:140px;height: auto;"><br>
            <span>{{ $t(255) }}</span>
          </div>
        </template>
      </el-table>
    </div>
    <div class="fund-box">
      <h3 class="title">{{ $t(225) }}</h3>
      <div class="flex">
        <el-form
          class="flex1"
          ref="form"
          :rules="rules"
          :model="form">
          <div class="item coin-select mb20">
            <div class="label mb5">{{ $t(196) }}</div>
            <img v-if="form.coin" class="curCoinLogo" :src="coinMsg[form.coin]?coinMsg[form.coin].logoUri:''" alt="" style="margin-right: 10px;">
            <el-select v-model="form.coin" class="coin-input" placeholder="" filterable>
              <el-option
                v-for="item in coinList"
                :key="item.coin"
                :value="item.coin"
                :label="item.coin">
                <div class="flex align-center">
                  <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" alt="" style="width: 24px;height:24px;margin-right: 10px;">
                  <span>{{ item.coin }}</span>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="item mt20">
            <div class="label mb5">{{ $t(226) }}</div>
            <el-form-item prop="address">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </div>
          <div class="item mt20">
            <div class="label mb5">{{ $t(229) }}</div>
            <el-form-item prop="chain">
              <el-input v-model="form.chain"></el-input>
            </el-form-item>
          </div>
          <div class="item mt20">
            <div class="label mb5 cursor flex align-center" @click="aliasExpand=!aliasExpand">{{ $t(204) }}({{ $t(208) }}) <i class="icon cursor" :class="{'el-icon-caret-bottom': !aliasExpand,'el-icon-caret-top':aliasExpand}"></i> </div>
            <el-form-item
              v-show="aliasExpand"
              prop="alias">
                <el-input v-model="form.alias"></el-input>  
            </el-form-item>
          </div>
          <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(177) }}</el-button></div>
        </el-form>
      </div>
    </div>
    <SimpleFooter></SimpleFooter>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import { getWallets, addWallet, deleteWallet } from '@/api'
import utils from '@/utils/misc'
import {findIndex} from '@/utils/misc'
export default {
  name: 'AddWallet',
  components: { SimpleHeader, SimpleFooter },
  mixins: [],
  data() {
    return {
      aliasExpand: false,
      loading:false,
      list: [],
      form: {
        coin: '',
        chain: '',
        address: '',
        aliasName: ''
      }
    }
  },
  watch: {
    walletsOptions(val) {
      if (val && val.length) {
        this.curWallet = val[0]
        this.form.addressId = this.curWallet.id
      } else {
        this.curWallet = {}
         this.form.addressId = ''
      }
    },
    coinList(val) {
      if (val.length) {
        this.coin = val[0].coin
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    coinMsg() {
      return this.$store.state.coins
    },
    configObj() {
      return window.configObj
    },
    coinList() {
      return this.$store.state.assets.assets.SPOT ? this.$store.state.assets.assets.SPOT.coins : []
    },
    chains() {
      return this.coinList.filter(item => item.coin === this.coin)
    },
    rules() {
      return {
        chain:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(227)))
            }
            callback()
          },
          trigger: 'blur'
        }],
        address:[{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error(this.$t(228)))
            }
            callback()
          },
          trigger: 'blur'
        }]
      }
    }
  },
  beforeMount() {
    if (this.$route.query.coin) {
      this.form.coin = this.$route.query.coin
    }
    this.getWallets()
  },
  methods: {
    getWallets() {
      this.loading = true
      getWallets().then(res => {
        this.list = res.addresses || []
      }).finally(() => {
        this.loading = false
      })
    },
    deleteWallet(id) {
      this.confirmWarning({
        title : this.$t(317),
        customClass: "financial-confirmWarning",
      }).then(() => {
        deleteWallet(id).then((res) => {
          this.$message.success(this.$t(318))
          this.getWallets()
        }).finally(() => {
          this.loading = false;
        });
      })
    },
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {...this.form}
          addWallet(obj).then(res => {
            this.showSuccessMsg(this.$t(224))
            this.$router.go(-1)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml{
    width: 800px;
    margin: 0 auto;
  }
  .fund-box{
    color: #1E2329;
    width: 800px;
    border-radius: 8px;
    margin: 5vh auto;
    background: #fff;
    padding: 40px;
    .title{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .tab{
      margin: 10px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #F5F5F5;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
    .coin-select{
      position: relative;
      .curCoinLogo{
        position: absolute;
        left: 10px;
        top: 28px;
        height: 24px;
        width: 24px;
        z-index:99;
      }
    }
  }
</style>
<style lang="scss">
.coin-input {
  .el-input__inner{
    padding-left: 40px;
  }
}
.amount {
  position: relative;
  .append{
    position: absolute;
    right: 20px;
    top: 37px;
    height: 24px;
    line-height: 24px;
    z-index:99;
  }
  .fee{
    position: absolute;
    bottom: -20px;
    left: 0px;
  }
  .el-input__inner{
    padding-right: 80px;
  }
}
</style>